import "./App.css";
import {
  getClientJSClient,
  // getFingerprintJSClient,
} from "./fingerprint";
import { Fragment, useEffect, useMemo, useState } from "react";

// import {useEffect, useMemo} from "react";

function App() {
  const [postResponse, setPostResponse] = useState(null);
  const [isBusy, setBusy] = useState(true);

  // useEffect(() => {
  //   getFingerprintJSClient().then((client) => setFP(client));
  // }, []);
  const clientJSHash = useMemo(
    () =>
      Object.getOwnPropertyNames(
        Object.getPrototypeOf(getClientJSClient())
      ).reduce((mp, method) => {
        const name = method.replace("get", "").replace("is", "");
        try {
          return {
            ...mp,
            [name]: getClientJSClient()[method](),
          };
        } catch (error) {
          return {
            ...mp,
            [name]: null,
          };
        }
      }, {}),
    []
  );
  // console.log({ clientJSHash, type: typeof clientJSHash });

  useEffect(() => {
    const postSignature = async (clientJSHash) => {
      const addr = new URL(window.location.href)
      console.log(addr.pathname)
      const routes = addr.pathname.split('/')
      //The path always starts with /, so routes[0] is always empty string
      const param = routes[1] ? routes[1] : '' 
  
      let res;
  
      if (param === 'check') {
        res = await fetch(
          "/checkInvite", {
            method: 'get',
            headers: {'Content-Type':'application/json'},
          }
        )
      } else {
        res = await fetch(
          "/registerInvite", {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
              "signature": clientJSHash['Fingerprint'],
              "browser": clientJSHash['Browser'],
              "os": clientJSHash['OS'],
              "osVersion": clientJSHash['OSVersion'],
              "inviteId": param
            })
          }
        )
      }
        
      const json = await res.json()
      setPostResponse(json)
      setBusy(false)
      // console.log(json)
    }  

    postSignature(clientJSHash);
  }, [clientJSHash]);

  if (isBusy) {
    return (
      <Fragment>
        <div id="waitDiv" className="row"><a href="/bars-rotate-fade.svg"></a></div>
        {/* <div>TEST</div> */}
      </Fragment>
    )
  } else {
    // console.log(JSON.stringify(clientJSHash))
    // console.log(`browser: ${clientJSHash["Browser"]}, OS: ${clientJSHash["OS"]}, OSVersion: ${clientJSHash["OSVersion"]}`)
    // return <div> {JSON.stringify(postResponse)} </div> 
    if (!postResponse || postResponse.redirect || !postResponse.show) {
      if (clientJSHash["Opera"] || clientJSHash["Chrome"] || clientJSHash["Safari"] || clientJSHash["Firefox"]) {
        window.location.replace('https://apps.apple.com/app/apple-store/id1191453603?pt=118483814&ct=groups&mt=8');
      } else {
        const url = window.location.href.replace("https://spfy.cc", "superfy://spfy.cc").replace("https://dev.spfy.cc", "superfydev://dev.spfy.cc")

        window.location.replace(url)
        setTimeout(function () { 
          // wait a second and then to go App Store or your Website
          // if the user hasn't installed yet.
          window.location.replace('https://apps.apple.com/app/apple-store/id1191453603?pt=118483814&ct=groups&mt=8');
        }, 1000);
      }

      return (
        <Fragment>
          <div className="row"><a href="/bars-rotate-fade.svg"></a></div>
          {/* <div>TEST</div> */}
        </Fragment>
      )
    } 
  }
  
  //Only if show is true, will not redirect
}

export default App;
